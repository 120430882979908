<template>
  <div class="space-y-4">
    <asom-alert
      class="mb-4"
      v-if="error"
      variant="error"
      :error-message="error"
    />
    <div class="w-full text-center" v-else-if="isLoading">
      <asom-icon icon="spinner" class="animate-spin" />
    </div>
    <template v-else>
      <h3 class="subheader">
        {{ $t("screens.cashManagement.Main.overview.title") }}
      </h3>
      <dl
        class="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4"
      >
        <aside class="rounded-lg bg-white overflow-hidden shadow">
          <div class="space-y-1 border-b boder-gray-200 p-3">
            <dt class="text-base font-normal text-gray-900">
              Certis Bags
            </dt>
            <dd class="text-3xl font-semibold text-gray-900 text-left">
              {{ data.certisBagCount }}
            </dd>
          </div>
          <ul class="divide-y divide-gray-200">
            <li class="px-3 py-2">
              <strong>{{ data.afcCertisBagCount }}</strong> in <strong>AFC</strong>
            </li>
            <li class="px-3 py-2">
              <strong>{{ data.pscCertisBagCount }}</strong> in <strong>PSC</strong>
            </li>
          </ul>
        </aside>
        <aside class="rounded-lg bg-white overflow-hidden shadow">
          <div class="space-y-1 border-b boder-gray-200 p-3">
            <dt class="text-base font-normal text-gray-900">
              TE Bags
            </dt>
            <dd class="text-3xl font-semibold text-gray-900 text-left">
              {{ data.teBagCount }}
            </dd>
          </div>
          <ul class="divide-y divide-gray-200">
            <li class="px-3 py-2">
              <strong>{{ data.afcTeBagCount }}</strong> in <strong>AFC</strong>
              <span
                class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"
              >
                Full
              </span>
            </li>
          </ul>
        </aside>
        <aside class="rounded-lg bg-white overflow-hidden shadow">
          <div class="space-y-1 border-b boder-gray-200 p-3">
            <dt class="text-base font-normal text-gray-900">
              Coin Boxes
            </dt>
            <dd class="text-3xl font-semibold text-gray-900 text-left">
              {{ data.coinBoxCount }}
            </dd>
          </div>
          <ul class="divide-y divide-gray-200">
            <li class="px-3 py-2">
              <strong>{{ data.gtmCoinBoxCount }}</strong> in <strong>GTM/TUK</strong>
            </li>
            <li class="px-3 py-2">
              <strong>{{ data.afcFullCoinBoxCount }}</strong> in <strong>AFC</strong>
              <span
                class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"
              >
                Full
              </span>
            </li>
            <li class="px-3 py-2">
              <strong>{{ data.afcEmptyCoinBoxCount }}</strong> in <strong>AFC</strong>
              <span
                class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800"
              >
                Empty
              </span>
            </li>
          </ul>
        </aside>
        <aside class="rounded-lg bg-white overflow-hidden shadow">
          <div class="space-y-1 border-b boder-gray-200 p-3">
            <dt class="text-base font-normal text-gray-900">
              Note Boxes
            </dt>
            <dd class="text-3xl font-semibold text-gray-900 text-left">
              {{ data.noteBoxCount }}
            </dd>
          </div>
          <ul class="divide-y divide-gray-200">
            <li class="px-3 py-2">
              <strong>{{ data.gtmNoteBoxCount }}</strong> in <strong>GTM/TUK</strong>
            </li>
            <li class="px-3 py-2">
              <strong>{{ data.afcFullNoteBoxCount }}</strong> in <strong>AFC</strong>
              <span
                class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"
              >
                Full
              </span>
            </li>
            <li class="px-3 py-2">
              <strong>{{ data.afcEmptyNoteBoxCount }}</strong> in <strong>AFC</strong>
              <span
                class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800"
              >
                Empty
              </span>
            </li>
          </ul>
        </aside>
        <aside class="rounded-lg bg-white overflow-hidden shadow">
          <div class="space-y-1 border-b boder-gray-200 p-3">
            <dt class="text-base font-normal text-gray-900">
              Coin Tubes
            </dt>
            <dd class="text-3xl font-semibold text-gray-900 text-left">
              {{ data.noOfCoinTubes }}
            </dd>
          </div>
          <ul class="divide-y divide-gray-200">
            <li class="px-3 py-2">
              <strong>{{ data.noOf1dollarCoinTubes }}</strong> 1$ <strong>coin tubes</strong>
            </li>
            <li class="px-3 py-2">
              <strong>{{ data.noOf50CentCoinTubes }}</strong> 50¢ <strong>coin tubes</strong>
            </li>
            <li class="px-3 py-2">
              <strong>{{ data.noOf20CentCoinTubes }}</strong> 20¢ <strong>coin tubes</strong>
            </li>
            <li class="px-3 py-2">
              <strong>{{ data.noOf10CentCoinTubes }}</strong> 10¢ <strong>coin tubes</strong>
            </li>
          </ul>
        </aside>
      </dl>

      <br />

      <h3 class="subheader">
        {{ $t("screens.cashManagement.Main.activityLogTitle") }}
      </h3>
      <activity-log-list :data="activityLogs" class="mt-5">
        <template v-slot:field_noOfTickets>No. of Tickets</template>
      </activity-log-list>
    </template>
  </div>
</template>
<script>
import get from "lodash.get";
import ActivityLogList from "./_ActivityLogList.vue";
import { getStationCashSummary } from "../../services/cashManagement.service";
import { mapGetters } from "vuex";

export default {
  components: {
    ActivityLogList,
  },
  data() {
    return {
      error: null,
      isLoading: false,
      data: {
        pscCertisBagCount: 0,
        afcCertisBagCount: 0,
        certisBagCount: 0,
        pscTeBagCount: 0,
        afcTeBagCount: 0,
        teBagCount: 0,
        gtmCoinBoxCount: 0,
        afcFullCoinBoxCount: 0,
        afcEmptyCoinBoxCount: 0,
        coinBoxCount: 0,
        gtmNoteBoxCount: 0,
        afcFullNoteBoxCount: 0,
        afcEmptyNoteBoxCount: 0,
        noteBoxCount: 0,
        noOf10CentCoinTubes: 0,
        noOf20CentCoinTubes: 0,
        noOf50CentCoinTubes: 0,
        noOf1dollarCoinTubes: 0,
        noOfCoinTubes: 0,
      },
      activityLogs: [],
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {
    ...mapGetters({
      stationId: "selectedStation/id",
      lineId: "selectedStation/lineId",
    }),
  },
  methods: {
    async loadData() {
      this.error = null;
      this.isLoading = true;
      const resp = await getStationCashSummary(this.stationId, this.lineId);
      if (resp.success) {
        this.data.pscCertisBagCount = get(resp, "payload.pscCertisBagCount", 0);
        this.data.afcCertisBagCount = get(resp, "payload.afcCertisBagCount", 0);
        this.data.certisBagCount = get(resp, "payload.certisBagCount", 0);
        this.data.pscTeBagCount = get(resp, "payload.pscTeBagCount", 0);
        this.data.afcTeBagCount = get(resp, "payload.afcTeBagCount", 0);
        this.data.teBagCount = get(resp, "payload.teBagCount", 0);
        this.data.gtmCoinBoxCount = get(resp, "payload.gtmCoinBoxCount", 0);
        this.data.afcFullCoinBoxCount = get(
          resp,
          "payload.afcFullCoinBoxCount",
          0
        );
        this.data.afcEmptyCoinBoxCount = get(
          resp,
          "payload.afcEmptyCoinBoxCount",
          0
        );
        this.data.coinBoxCount = get(resp, "payload.coinBoxCount", 0);
        this.data.gtmNoteBoxCount = get(resp, "payload.gtmNoteBoxCount", 0);
        this.data.afcFullNoteBoxCount = get(
          resp,
          "payload.afcFullNoteBoxCount",
          0
        );
        this.data.afcEmptyNoteBoxCount = get(
          resp,
          "payload.afcEmptyNoteBoxCount",
          0
        );
        this.data.noteBoxCount = get(resp, "payload.noteBoxCount", 0);
        this.data.noOf10CentCoinTubes = get(
          resp,
          "payload.noOf10CentCoinTubes",
          0
        );
        this.data.noOf20CentCoinTubes = get(
          resp,
          "payload.noOf20CentCoinTubes",
          0
        );
        this.data.noOf50CentCoinTubes = get(
          resp,
          "payload.noOf50CentCoinTubes",
          0
        );
        this.data.noOf1dollarCoinTubes = get(
          resp,
          "payload.noOf1dollarCoinTubes",
          0
        );
        this.data.noOfCoinTubes = get(resp, "payload.noOfCoinTubes", 0);
        let _activityLogs = get(resp, "payload.activityLogs", []);
        const fields = ['noOfTickets', 'psm', 'inputType', 'gtm', 'input', 'description', 'waived', 'payment', 'receipt'];
        _activityLogs.forEach( log => {
          let result = [];
          var obj = get(log, "fieldChanges", {});
          if(obj){
            fields.forEach( field => {
              if(obj[field] || obj[field + 'Updated']){
                let temp =
                { fieldName: field,
                  values: {
                    oldValue: obj[field],
                    newValue: obj[field + 'Updated']
                  }
                }
                result.push(temp);
              }
            })
          }
          log.fieldChanges = result;
        })
        this.activityLogs = _activityLogs;
      } else {
        this.error = resp.payload;
      }
      this.isLoading = false;
    },
  },
};
</script>
